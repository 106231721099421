export default {
    data() {
        return {
            sysTypes: [],
            sysTypesMap: {},
            unitSysSubtypes: [],
            unitSysSubtypesMap: {},
            personSysSubtypes: [],
            personSysSubtypesMap: {},
            positionStatus: [],
            positionStatusMap: {},
            customsStatus: [],
            customsStatusMap: {},
            visitStatus: [],
            visitStatusMap: {},
            fullEmpties: [],
            fullEmptiesMap: {},
            directions: [],
            directionsMap: {},
            transportTypes: [],
            transportTypesMap: {},
            iconMap: {},
            roles: [],
            rolesMap: {},
            countTypes: [],
            countTypesMap: {},
            handlingStatus: [],
            handlingStatusMap: {},
            yesNoList : [],
            yesNoMap : [],
            cyclesList: [],
            cyclesMap : {},
        };
    },
    async mounted() {
        await this.loadCombo();
        this.iconMap = this.$api.yardData.getIconMap();
    },
    methods: {
        async loadCombo() {
            const all = await this.$api.yardData.getLookups();
            //console.log(all);
            this.sysTypes = this.mapList(this.sysTypesMap, all.sysTypes);
            this.unitSysSubtypes = this.mapList(this.unitSysSubtypesMap, all.unitTypes);
            this.personSysSubtypes = this.mapList(this.personSysSubtypesMap, all.personTypes);
            this.positionStatus = this.mapList(this.positionStatusMap, all.positionStatus);
            this.customsStatus = this.mapList(this.customsStatusMap, all.customsStatus);
            this.visitStatus = this.mapList(this.visitStatusMap, all.visitStatus);
            this.fullEmpties = this.mapList(this.fullEmptiesMap, all.fullEmpties);
            this.directions = this.mapList(this.directionsMap, all.directions);
            this.transportTypes = this.mapList(this.transportTypesMap, all.transportTypes);
            this.roles = this.mapList(this.rolesMap, all.roles);
            this.countTypes = this.mapList(this.countTypesMap, all.countTypes);
            this.handlingStatus = this.mapList(this.handlingStatusMap, all.handlingStatus);
            this.yesNoList = this.mapList(this.yesNoMap, all.yesNo);
            this.cyclesList = this.mapList(this.cyclesMap, all.cycles);
        },
        mapList(map, list) {
            list.forEach(element => {
                map[element.value] = element.text;
            });
            return list;
        },

        /** Se tra i parent c'è  VisitDetails, manda evento, altrimenti reindirizza pagina VisitDetails */
        goToVisitDetails(visitId, visitSysType) {
            console.log("goToVisitDetails visit", visitId, visitSysType);
            this.goToVisitDetailsRecursive(this.$parent, visitId, visitSysType);
        },
        goToVisitDetailsRecursive(parent, visitId, visitSysType) {
            if (parent == null) {
                this.$router.push({ path: "/yard/inventory/visitDetails/" + visitId + "/" + visitSysType });
                return;
            }
            if (parent.$options.name == "VisitDetails") {
                const standalone = parent.standalone
                console.log("Found VisitDetails!. standalone?", standalone);
                if (standalone != true) parent.$emit("visit-changed", visitId, visitSysType);
                else this.$router.push({ path: "/yard/inventory/visitDetails/" + visitId + "/" + visitSysType });
                return;
            }
            this.goToVisitDetailsRecursive(parent.$parent, visitId, visitSysType);
        },
    },
    computed: {

    }
}