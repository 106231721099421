<template>
  <div class="content-section introduction">
    <div class="feature-intro">
      <h1>{{ tLabel("Create Stock Header") }}</h1>
    </div>

    <v-form ref="createStockHeaderForm">
      <v-container fluid>
        <v-row>
          <v-col cols="3">
            <v-autocomplete :label="tLabel('Client')" v-model="stockHeaderReportReq.filter.clientInfo" 
            :items="owners"  item-text="code" return-object />
          </v-col>
          <v-col cols="3">
            <v-select clearable :label="tLabel('Unit Full/Unit Empty')" v-model="stockHeaderReportReq.filter.fullEmpty" :items="fullEmpties" />
          </v-col>
          <v-col cols="3">
            <DateTimeAdapter :label="tLabelRequired('Date to')" v-model.trim="time" :rules="[rules.required]" defaultTime="23:59:59" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-select clearable :label="tLabel('Damaged')" v-model="stockHeaderReportReq.filter.damaged" :items="yesNoList" />
          </v-col>
          <v-col cols="3">
            <v-select clearable :label="tLabel('Dangerous')" v-model="stockHeaderReportReq.filter.dangerous" :items="yesNoList" />
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-container>
      <v-row>
        <v-col cols="6" style="text-align: right">
          <v-btn color="success" class="pa-2 ma-2" @click="save" medium>Salva</v-btn>
          <v-btn color="error" class="pa-2 ma-2" @click="discard" medium>Annulla</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import i18n from "../../i18n";
import FormatsMixins from "../../mixins/FormatsMixins";
import DateTimeAdapter from "../../components/anagrafiche/DateTimeAdapter";
import YardEnumMixin from "../../mixins/yard/EnumMixin";
import moment from "moment";

export default {
  name: "CreateStockHeader",
  title: i18n.t("Crea Giacenze Report"),
  data() {
    return {
      stockHeaderReportReq: {
        type: "STOCK",
        filter: {},
      },
      time: null,
      owners: [],
    };
  },
  async mounted() {
    this.owners= await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/owners");
  },
  methods: {
    async save() {
      const valid = this.$refs.createStockHeaderForm.validate();
      if (!valid) {
        return;
      }
      this.stockHeaderReportReq.filter.time = JSON.parse(JSON.stringify(moment(this.time).format("YYYY-MM-DDTHH:mm:ss")));
      //this.stockHeaderReportReq.filter.dangerous = this.stockHeaderReportReq.filter.dangerous && this.stockHeaderReportReq.filter.dangerous ? "Y" : "N";
      console.log("save stockHeader", this.stockHeaderReportReq);
      await this.$api.reportData.report(this.stockHeaderReportReq);
      this.$router.go(-1);
    },
    discard() {
      console.log("discard stockHeader", this.stockHeaderReportReq);
      this.$router.go(-1);
    },
  },
  mixins: [FormatsMixins, YardEnumMixin],
  components: {
    DateTimeAdapter,
  },
};
</script>
